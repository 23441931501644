<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Tasks</h2>
    </div>
    <div :class="'ibox-content p-md' + (tasksLoading ? ' sk-loading' : '')">

      <div v-if="tasksLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>
        <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" :reduce="listDBsReduce" v-model="form.options.dbConfig" placeholder="Select a target DB" @keypress.enter.native.prevent="">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!form.options.dbConfig"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </p>

      <b-table 
          outlined striped
          :items="tasksProvider"
          :fields="tasksFields"
          ref="listTasks">
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="run(row.item)">Run</a>
        </template>
      </b-table>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';

import { useListDBs } from '@root/src/client/composables/admin/useListDBs';


export default defineComponent({
  props: {
      
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { listDBs, isListDBLoading, listDBsReduce, listDBsOptionLabel } = useListDBs(props, context);

    const tasksLoading:Ref<boolean> = ref(false);

    const form:any = reactive({
      options : {
        dbConfig : null,
      }
    })

    const tasksFields = [
      {
        key: "name"
      },
      {
        key: "options"
      }
    ];

    const tasksProvider = (ctx:any) => {
      var options:api.ApiVueOptions =  {
        app: app
      }

      return api.getAPI('/api/admin/application/tasks', options).then((response:any) => {
        if(response.tasks) {  
          return response.tasks;
        }
        else {
          return [];
        }
      })
    }

    const run = (task:any) => {
      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {
        ...form
      }

      tasksLoading.value = true;

      return api.postAPI('/api/admin/application/tasks/run/'+task.name, input, options).then((response:any) => {
        tasksLoading.value = false;
      })
    }

    return {
      run,
      tasksLoading,
      listDBs,
      listDBsOptionLabel,
      listDBsReduce,
      form,
      tasksProvider,
      tasksFields
    }
  }
})
</script>