<template>
  <section id="content">
    <PageTitle title="Application"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <Tasks></Tasks>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <Scripts></Scripts>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import Tasks from '../../panels/admin/application/Tasks.vue';
import Scripts from '../../panels/admin/application/Scripts.vue';


@Component({
  components: { 
    PageTitle,
    Tasks,
    Scripts
  }
})
export default class Application extends mixins(GenericPage) {
  
}
</script>