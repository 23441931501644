<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Scripts</h2>
    </div>
    <div :class="'ibox-content p-md' + (scriptsLoading ? ' sk-loading' : '')">

      <div v-if="scriptsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      
      <form role="form" @submit="onFormSubmit" name="executeScriptForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" :reduce="listDBsReduce" v-model="form.options.dbConfig" placeholder="Select a target DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.options.dbConfig"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listScripts" v-model="form.name" placeholder="Select a script" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.name"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Execute</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';

import { useListDBs } from '@root/src/client/composables/admin/useListDBs';


export default defineComponent({
  props: {
      
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { listDBs, isListDBLoading, listDBsReduce, listDBsOptionLabel } = useListDBs(props, context);
    
    const listScripts:Ref<any[]> = ref([]);

    const listDBsLoading:Ref<boolean> = ref(false);
    const listScriptsLoading:Ref<boolean> = ref(false);

    const scriptsLoading = computed(() => {
      return listDBsLoading.value || listScriptsLoading.value;
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=executeScriptForm] button.ladda-button' );
      laddaSubmit = Ladda.create(button!);
    })

    const form:any = reactive({
      options : {
        dbConfig : null,
      },
      name : ""
    })

    const updateListScripts = () => {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: app
      }
      listScriptsLoading.value = true;
      api.getAPI('/api/admin/application/scripts', options).then((response:any) => {
        listScriptsLoading.value = false;
        if(response.scripts) {  
          listScripts.value = response.scripts;
        }
        else {
          listScripts.value = [];
        }
      });
    }
    updateListScripts();

    const onFormSubmit = (evt:Event) => {
      evt.preventDefault();

      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {
        ...form
      }

      laddaSubmit!.start();

      return api.postAPI('/api/admin/application/scripts/run', input,  options).then((response:any) => {
        laddaSubmit!.stop();
      })
    }

    return {
      onFormSubmit,
      scriptsLoading,
      listDBs,
      listDBsOptionLabel,
      listDBsReduce,
      form,
      listScripts
    }
  }
})
</script>